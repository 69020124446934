import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Layout } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

const VerificationPendingPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  useEffect(() => {
    // Set timeout to logout after 10 seconds
    const timer = setTimeout(() => {
      logout({ returnTo: window.location.origin });
    }, 10000); // 10 seconds

    // Cleanup timer if component unmounts
    return () => clearTimeout(timer);
  }, [logout]);

  return (
    <Layout style={{ minHeight: '100vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Typography.Title level={3}>
          Account Verification Pending
        </Typography.Title>
        <Typography.Text style={{ fontSize: '16px', display: 'block', marginBottom: '24px' }}>
          Your account is pending verification. We'll notify you once it's verified. This usually takes 24-48 hours.
        </Typography.Text>
        <Typography.Text type="secondary" style={{ display: 'block', marginBottom: '16px' }}>
          You will be automatically logged out in a few seconds...
        </Typography.Text>
        <Button type="primary" onClick={() => {
          logout({ returnTo: window.location.origin });
          navigate('/');
        }}>
          Return to Home
        </Button>
      </div>
    </Layout>
  );
};

export default VerificationPendingPage; 