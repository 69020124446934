import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Row, Col, Table, Space, Modal, message, Form, Input, InputNumber, Typography } from 'antd';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import Confirmation from './confirmation';
import { useNavigate, useLocation } from 'react-router-dom';
import { DashboardOutlined, CalendarOutlined, HistoryOutlined } from '@ant-design/icons';

const { Header, Sider, Content, Footer } = Layout;
const { Title } = Typography;

// Declare apiUrl at the top of your component file
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const Booking = () => {
  const { logout, user, } = useAuth0();
  const { name: userName, email: userEmail } = user;
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [date, setDate] = useState(new Date());
  const [activeView, setActiveView] = useState('dashboard');
  const [weeklySlots, setWeeklySlots] = useState({});
  const [displayedDays, setDisplayedDays] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState({});
  const [boundaryDate, setBoundaryDate] = useState(null);
  const [currency, setCurrency] = useState('USD'); // Currency for selected slot
  const [paymentAmount, setPaymentAmount] = useState(0); // Amount to be paid
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [errors, setErrors] = useState({}); // Error messages for form validation
  const [name, setName] = useState(userName ?? ''); // Student name
  const [email, setEmail] = useState(userEmail ?? ''); // Student email
  const [phone, setPhone] = useState(''); // Student phone number
  const [activeBookings, setActiveBookings] = useState([]);
  const [historyBookings, setHistoryBookings] = useState([]);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [couponCode, setCouponCode] = useState('');
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const [couponError, setCouponError] = useState('');
  const [discountPercent, setDiscountPercent] = useState(0);
  const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [cancellingId, setCancellingId] = useState(null);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('paid'); // Default filter set to 'paid'

  // Add simple hash navigation for dashboard
  useEffect(() => {
    if (!location.hash) {
      navigate('/booking#dashboard', { replace: true });
    }
  }, []);

  // Sidebar items
  const menuItems = [
    {
      key: '1',
      label: 'Dashboard',
      onClick: () => navigate('/booking#dashboard'),
      icon: <DashboardOutlined />,
    },
    { 
      key: '2', 
      label: 'Active Classes',
      onClick: () => navigate('/booking#activeclasses'),
      icon: <CalendarOutlined />,
    },
    { 
      key: '3', 
      label: 'History',
      onClick: () => navigate('/booking#history'),
      icon: <HistoryOutlined />,
    },
    { 
      key: '4', 
      label: 'Contact Us',
      onClick: () => setActiveView('contactUs')
    },
    { 
      key: '5', 
      label: 'Terms & Conditions',
      onClick: () => setActiveView('termsConditions')
    },
    { 
      key: '6', 
      label: 'Logout', 
      onClick: () => logout({ returnTo: window.location.origin }) 
    },
  ];



  const handleSlotSelect = (slot) => {
    

    // Define updatedSlot here before using it
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    

    // Set currency and amount based on timezone
  let slotCurrency, slotAmount;
  if (localTimeZone.includes('Asia/')) {
    
    slotCurrency = 'INR';
    slotAmount = 999;
  } else {
    
    slotCurrency = 'USD';
    slotAmount = 24.99;
  }

  const updatedSlot = {
    ...slot,
    currency: slotCurrency,
    paymentAmount: slotAmount
  };

  setSelectedSlot(updatedSlot);
  setCurrency(slotCurrency);
  setPaymentAmount(slotAmount);
  setShowModal(true);

  
};

  // Fetch slots once and cache them
  const fetchWeeklySlots = async () => {
    try {
      
      const response = await fetch(`${apiUrl}/api/slots`);
      const allSlots = await response.json();
      

      const slotsByDay = {};
      const today = dayjs();

      // Only process slots that are in the future
      allSlots.forEach(slot => {
        const slotDate = dayjs(slot.dateTime);
        if (slotDate.isAfter(today)) {
          const dayKey = slotDate.format('YYYY-MM-DD');
          if (!slotsByDay[dayKey]) {
            slotsByDay[dayKey] = [];
          }
          slotsByDay[dayKey].push({
            ...slot,
            formattedTime: slotDate.format('h:mm A')
          });
        }
      });

      // Sort slots within each day
      Object.keys(slotsByDay).forEach(day => {
        slotsByDay[day].sort((a, b) => 
          dayjs(a.dateTime).diff(dayjs(b.dateTime))
        );
      });

      
      setWeeklySlots(slotsByDay);
    } catch (err) {
      
    }
  };

  useEffect(() => {
    fetchWeeklySlots();
  }, []);

  // Generate next 5 days starting from `startDate`, but include current day as unavailable
  const generateNextFiveDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 5; i++) {
      days.push(dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'));
    }
    return days;
  };

  // Handle boundary-based date updates
  const handleDateSelect = (selectedDate) => {
    setDate(selectedDate);

    const newDisplayedDays = generateNextFiveDays(selectedDate);
    setDisplayedDays(newDisplayedDays);
    setBoundaryDate(dayjs(newDisplayedDays[newDisplayedDays.length - 1]));

    const newFilteredSlots = {};
    newDisplayedDays.forEach(day => {
      newFilteredSlots[day] = weeklySlots[day] || [];
    });
    setFilteredSlots(newFilteredSlots);
  };

  useEffect(() => {
    const initialDisplayedDays = generateNextFiveDays(date);
    setDisplayedDays(initialDisplayedDays);
    setBoundaryDate(dayjs(initialDisplayedDays[initialDisplayedDays.length - 1]));

    const slotsForInitialRange = {};
    initialDisplayedDays.forEach(day => {
      slotsForInitialRange[day] = weeklySlots[day] || [];
    });
    setFilteredSlots(slotsForInitialRange);
  }, [weeklySlots]);

  const checkOrCreateStudentProfile = async () => {
    try {
      
      const response = await fetch(`${apiUrl}/api/check-or-create-student`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.text();
        
        throw new Error('Failed to create student profile');
      }
      
      const data = await response.json();
      
      return data.success;
    } catch (err) {
      
      return false;
    }
  };


  useEffect(() => {
    if (user) {
      checkOrCreateStudentProfile();
    }
  }, [user]);


  const handleCouponApply = async () => {
    setCouponError('');
    setDiscountedAmount(null);
    setDiscountPercent(0);
    
    try {
      const response = await fetch(`${apiUrl}/api/coupons/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: couponCode })
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        setCouponError(data.error);
        return;
      }
  
      const discount = (paymentAmount * data.discount) / 100;
      const finalAmount = paymentAmount - discount;
      setDiscountedAmount(finalAmount);
      setDiscountPercent(data.discount);
    } catch (error) {
      setCouponError('Error validating coupon');
      
    }
  };

  // Function to calculate total amount
  const calculateTotalAmount = (baseAmount) => {
    const dancehallFee = currency === 'USD' ? 2.5 : 99; // Fixed fee
    const razorpayFee = baseAmount * 0.024; // Correct Razorpay fee percentage
    return (baseAmount + dancehallFee + razorpayFee).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedSlot) {
      setErrors({ form: 'Please select a class slot' });
      
      return;
    }

    try {
      let discountedAmountFinal = discountedAmount || paymentAmount;
      

      // Validate the coupon before proceeding
      if (couponCode) {
       
        const couponResponse = await fetch(`${apiUrl}/api/coupons/apply`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code: couponCode })
        });

        if (!couponResponse.ok) {
          const errorData = await couponResponse.json();
          setErrors({ coupon: errorData.message || 'Invalid coupon' });
          
          return; // Stop execution if coupon validation fails
        }

        const couponResult = await couponResponse.json();
       

        // Calculate the discounted amount using the discount value
        if (couponResult && couponResult.coupon && couponResult.coupon.discount !== undefined) {
          const discountValue = couponResult.coupon.discount;
          discountedAmountFinal = paymentAmount - (paymentAmount * (discountValue / 100));
      
        } else {
          
          setErrors({ coupon: 'Failed to apply coupon' });
          return;
        }
      }

      // Calculate the total amount (class cost + fees)
      const totalAmount = calculateTotalAmount(discountedAmountFinal);
  

      // Check or create the student's profile
      const profileCreated = await checkOrCreateStudentProfile();
      if (!profileCreated) {
        throw new Error('Unable to create/verify student profile');
      }
  

      // Create the booking and send the total amount to the backend
      const response = await fetch(`${apiUrl}/create-booking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          slotId: selectedSlot._id,
          studentName: name,
          studentEmail: email,
          studentPhone: phone,
          paymentAmount: parseFloat(totalAmount), // Send the total amount including fees
          currency,
          couponApplied: couponCode || null, // Pass the coupon code if applied
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
      
        throw new Error(errorText);
      }

      const data = await response.json();
     

      // Prepare Razorpay payment options
      const options = {
        amount: Math.round(totalAmount * 100), // Convert to smallest currency unit
        currency: data.currency,
        name: 'Class Booking',
        description: 'Payment for class booking',
        order_id: data.orderId, // Razorpay order ID
        handler: async (response) => {
          try {
            const successResponse = await fetch(`${apiUrl}/payment-success`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              }),
            });

            if (!successResponse.ok) {
              const errorText = await successResponse.text();
    
              throw new Error(errorText);
            }

            const successData = await successResponse.json();
      

            // Handle booking confirmation
            const confirmationDetails = {
              bookingId: successData.booking.bookingId,
              name: name,
              email: email,
              dateTime: selectedSlot.dateTime,
              amount: totalAmount,
              currency: currency,
              googleMeetLink: successData.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho',
            };

            setConfirmationDetails(confirmationDetails);
            setIsBookingConfirmed(true);
            setShowModal(false);
            await fetchWeeklySlots();
          } catch (err) {
            setErrors({ form: `Failed to update slot status: ${err.message}` });
        
          }
        },
        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.on('payment.failed', function (response) {
        setErrors({ form: `Payment failed: ${response.error.description}` });
    
      });
      rzp.open();
   
    } catch (err) {
      setErrors({ form: `Failed to create booking: ${err.message}` });
     
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    if (activeView === 'activeClasses' || activeView === 'history') {
      const fetchBookings = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/student/${user.email}/bookings`);
          const data = await response.json();
          const currentDate = new Date();
          
          // Split bookings into active and history
          const active = data.filter(booking => new Date(booking.slotId?.dateTime) > currentDate);
          const history = data.filter(booking => new Date(booking.slotId?.dateTime) <= currentDate);
          
          setActiveBookings(active);
          setHistoryBookings(history);
        } catch (err) {
         
        }
      };
      fetchBookings();
    }
  }, [activeView, user.email]);

  const handleCancelBooking = (id) => {
    Modal.confirm({
      title: 'Cancel Booking',
      content: 'Are you sure you want to cancel this booking?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onCancel(id)
    });
  };

  const onCancel = async (id) => {
    setCancellingId(id);
    try {
      // First update the UI optimistically
      setActiveBookings(prevBookings => 
        prevBookings.map(booking => 
          booking._id === id 
            ? { ...booking, bookingStatus: 'cancelled' }  // Only update booking status
            : booking
        )
      );

      // Then make the API call
      const response = await fetch(`${apiUrl}/api/cancel-booking/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to cancel booking');
      }

      // Refresh the bookings to get the latest state
      const updatedResponse = await fetch(`${apiUrl}/api/student/${user.email}/bookings`);
      const data = await response.json();
      const currentDate = new Date();
      
      // Update active bookings while preserving payment status
      setActiveBookings(data.filter(booking => 
        new Date(booking.slotId?.dateTime) > currentDate
      ));

    } catch (err) {
      
      // Revert the optimistic update if the API call failed
      const response = await fetch(`${apiUrl}/api/student/${user.email}/bookings`);
      const data = await response.json();
      const currentDate = new Date();
      setActiveBookings(data.filter(booking => 
        new Date(booking.slotId?.dateTime) > currentDate
      ));
    } finally {
      setCancellingId(null);
    }
  };

  const activeClassesColumns = [
    {
      title: 'Class Date',
      dataIndex: 'classDate',
      key: 'classDate',
    },
    {
      title: 'Class Time',
      dataIndex: 'classTime',
      key: 'classTime',
    },
    {
      title: 'Google Meet Link',
      dataIndex: 'googleMeetLink',
      key: 'googleMeetLink',
      render: (link) => (
        <a href={link} target="_blank" rel="noopener noreferrer">
          Join
        </a>
      ),
    },
    {
      title: 'Class Cost',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (status) => status === 'paid' ? 'Paid' : 'Pending'
    },
    {
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      key: 'bookingStatus',
      render: (status) => status === 'cancelled' ? 'Cancelled' : 'Active'
    },
    {
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      key: 'bookingDate',
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (_, record) => (
    //     <Space>
    //       <Button 
    //         loading={cancellingId === record.id}
    //         onClick={() => handleCancelBooking(record.id)}
    //         disabled={record.bookingStatus === 'cancelled'}
    //       >
    //         Cancel
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  const historyClassesColumns = [
    {
      title: 'Class Date',
      dataIndex: 'classDate',
      key: 'classDate',
    },
    {
      title: 'Class Time',
      dataIndex: 'classTime',
      key: 'classTime',
    },
    {
      title: 'Class Cost',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
  ];

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (localTimeZone.includes('Asia/')) {
      setCurrency('INR');
      setPaymentAmount(999);
    } else {
      setCurrency('USD');
      setPaymentAmount(24.99);
    }
  }, []); // Empty dependency array means this runs once when component mounts

  const handlePaymentSuccess = async (response) => {
   
    try {
      const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
      
      const verifyResponse = await fetch(`${apiUrl}/payment-success`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          order_id: razorpay_order_id,
          payment_id: razorpay_payment_id,
          signature: razorpay_signature
        })
      });

      const data = await verifyResponse.json();
      

      if (verifyResponse.ok && data.success) {
      
        const confirmationDetails = {
          bookingId: data.booking.bookingId,
          name: name,
          email: email,
          dateTime: data.booking.dateTime,
          amount: data.booking.amount,
          currency: data.booking.currency,
          googleMeetLink: data.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho'
        };
        
        // Navigate to confirmation with state
        navigate('/confirmation', { 
          state: { confirmationDetails }
        });
      } else {
        throw new Error('Payment verification failed');
      }
    } catch (error) {
  
      setErrors({ payment: 'Payment verification failed' });
    }
  };

  useEffect(() => {
    if (!location.hash) {
      navigate('/booking#dashboard');
    } else if (location.hash === '#activeclasses') {
      setActiveView('activeClasses');
    } else if (location.hash === '#dashboard') {
      setActiveView('dashboard');
    } else if (location.hash === '#history') {
      setActiveView('history');
    }
  }, [location.hash, navigate]);

  // Function to handle filter change
  const handleFilterChange = (e) => {
    setPaymentStatusFilter(e.target.value);
  };

  // Filtered bookings based on selected payment status
  const filteredActiveBookings = activeBookings.filter(booking => {
   
    return paymentStatusFilter === 'all' || booking.paymentStatus === paymentStatusFilter;
  });

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header 
          style={{ 
            color: 'white', 
            fontSize: '1.5em', 
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <span>Welcome to Mani's DanceHall, {userName}</span>
        </Header>
      <Layout>
      <Sider width={200}>
        <Menu 
          mode="inline" 
          defaultSelectedKeys={[
            location.hash === '#activeclasses' ? '2' : 
            location.hash === '#history' ? '3' : '1'
          ]} 
          style={{ height: '100%' }} 
          items={menuItems} 
        />
      </Sider>
        <Content style={{ padding: '0 24px', minHeight: 280 }}>
          {isBookingConfirmed && confirmationDetails ? (
            <Confirmation bookingDetails={confirmationDetails} />
          ) : (
            <>
              {activeView === 'dashboard' && (
                <div className="dashboard-content">
                <div className="calendar-section" style={{ marginTop: '24px' }}>
                  <Title level={2} style={{ marginBottom: '16px' }}>Select a date</Title>
                  <Calendar onChange={handleDateSelect} value={date} minDate={new Date()} locale="en-US" />
                </div>
                  <div className="available-classes">
                    <h2>Available Classes</h2>
                      <div className="class-list-body">
                        {/* Display the slots for each day */}
                        <Row gutter={[16, 16]}>
                          {displayedDays.map((day) => (
                            <Col span={4} key={day}>
                              <h3 style={{ textAlign: 'center' }}>{dayjs(day).format('ddd, MMM DD')}</h3>
                              {filteredSlots[day] && filteredSlots[day].length > 0 ? (
                                <ul style={{ padding: 0, listStyleType: 'none' }}>
                                  {filteredSlots[day].map((slot) => (
                                    <li key={slot._id} style={{ marginBottom: '8px' }}>
                                      <Button
                                        className={selectedSlot && selectedSlot._id === slot._id ? 'selected' : ''}
                                        style={{ width: '100%' }}
                                        onClick={() => handleSlotSelect(slot)}
                                      >
                                        {dayjs(slot.dateTime).format('h:mm A')}
                                        {/* <br /> */}
                                        {/* {slot.currency} {slot.paymentAmount} */}
                                      </Button>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p style={{ color: 'gray', textAlign: 'center' }}>No slots</p>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </div>
                    
                  </div>
                </div>
              )}
              {activeView === 'activeClasses' && (
                <div style={{ padding: '24px' }}>
                  <h2>Your Active Bookings</h2>
                  <div>
                    <label>Filter by Payment Status: </label>
                    <select value={paymentStatusFilter} onChange={handleFilterChange}>
                      <option value="all">All</option>
                      <option value="paid">Paid</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>
                  <Table
                    columns={activeClassesColumns}
                    dataSource={filteredActiveBookings.map(booking => ({
                      key: booking._id,
                      id: booking._id,
                      classDate: formatDate(new Date(booking.slotId?.dateTime)),
                      classTime: new Date(booking.slotId?.dateTime).toLocaleTimeString('en-US', { timeZone: localTimeZone }),
                      googleMeetLink: booking.googleMeetLink,
                      paymentAmount: booking.paymentAmount,
                      paymentStatus: booking.paymentStatus,
                      bookingStatus: booking.bookingStatus,
                      bookingDate: formatDate(new Date(booking.createdAt))
                    }))}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
              {activeView === 'history' && (
                <div style={{ padding: '24px' }}>
                  <h2>History Bookings</h2>
                  <Table
                    columns={historyClassesColumns}
                    dataSource={historyBookings.map(booking => ({
                      key: booking._id,
                      id: booking._id,
                      classDate: formatDate(new Date(booking.slotId?.dateTime)),
                      classTime: new Date(booking.slotId?.dateTime).toLocaleTimeString('en-US', { timeZone: localTimeZone }),
                      paymentAmount: booking.paymentAmount,
                      paymentStatus: booking.paymentStatus,
                    }))}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
            </>
          )}
        </Content>
      </Layout>
      <Footer style={{ textAlign: 'center' }}>My Dance Hall ©{new Date().getFullYear()}</Footer>

  

{/* Payment Form Modal */}
{/* Payment Form Modal */}
<Modal 
  open={showModal} 
  title="Complete Your Payment" 
  onCancel={() => {
    setShowModal(false);
    setCouponCode('');
    setDiscountedAmount(null);
    setCouponError('');
  }} 
  footer={[
    <Button 
      key="cancel" 
      onClick={() => {
        setShowModal(false);
        setCouponCode('');
        setDiscountedAmount(null);
        setCouponError('');
      }}
      style={{ height: '40px', fontSize: '16px' }}
    >
      Cancel
    </Button>,
    <Button 
      key="submit" 
      type="primary" 
      onClick={handleSubmit}
      style={{ height: '40px', fontSize: '16px' }}
    >
      Dance Away!
    </Button>
  ]}
  width={600}
>
  <Form 
    name="paymentForm" 
    layout="vertical"
    style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: '16px',
      padding: '0 12px'
    }}
  >
    {/* User Details - Improved Layout */}
    <div style={{ 
      display: 'grid', 
      gridTemplateColumns: 'repeat(2, 1fr)', 
      gap: '16px',
      backgroundColor: '#f5f5f5',
      padding: '16px',
      borderRadius: '8px'
    }}>
      {/* Name Section */}
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: '8px'
      }}>
        <label style={{ 
          fontWeight: 'bold', 
          color: '#333',
          fontSize: '14px'
        }}>
          Name
        </label>
        <div style={{ 
          backgroundColor: 'white', 
          padding: '8px', 
          borderRadius: '4px',
          border: '1px solid #d9d9d9'
        }}>
          {name}
        </div>
      </div>

      {/* Email Section */}
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: '8px'
      }}>
        <label style={{ 
          fontWeight: 'bold', 
          color: '#333',
          fontSize: '14px'
        }}>
          Email
        </label>
        <div style={{ 
          backgroundColor: 'white', 
          padding: '8px', 
          borderRadius: '4px',
          border: '1px solid #d9d9d9'
        }}>
          {email}
        </div>
      </div>

      {/* Phone Section */}
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: '8px'
      }}>
        <label style={{ 
          fontWeight: 'bold', 
          color: '#333',
          fontSize: '14px'
        }}>
          Phone Number
        </label>
        <Input 
          value={phone} 
          onChange={(e) => setPhone(e.target.value)} 
          placeholder="Enter phone number"
          style={{ 
            height: '40px',
            borderRadius: '4px'
          }}
        />
      </div>

      {/* Coupon Section */}
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: '8px'
      }}>
        <label style={{ 
          fontWeight: 'bold', 
          color: '#333',
          fontSize: '14px'
        }}>
          Coupon Code (Optional)
        </label>
        <Input.Group compact style={{ display: 'flex' }}>
          <Input 
            style={{ 
              height: '40px',
              flex: 1,
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px'
            }} 
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
            placeholder="Enter coupon"
          />
          <Button 
            type="primary" 
            onClick={handleCouponApply}
            style={{ 
              height: '40px',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px'
            }}
          >Apply
          </Button>
        </Input.Group>
      </div>
    </div>

    {/* Coupon Feedback */}
    {(couponError || discountedAmount) && (
      <div style={{ 
        padding: '8px',
        borderRadius: '4px',
        backgroundColor: couponError ? '#fff2f0' : '#f6ffed',
        color: couponError ? '#ff4d4f' : '#52c41a',
        fontSize: '12px',
        textAlign: 'center'
      }}>
        {couponError || 'Discount applied!'}
      </div>
    )}

    {/* Price Breakdown */}
    <div style={{ 
      backgroundColor: '#f5f5f5', 
      padding: '16px', 
      borderRadius: '8px' 
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
        <span>Class Cost</span>
        <span>{currency} {discountedAmount || paymentAmount}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
        <span>Dancehall Service Fee</span>
        <span>{currency} {(currency === 'USD' ? 2.5 : 99).toFixed(2)}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
        <span>Razorpay Service Fee</span>
        <span>{currency} {((discountedAmount || paymentAmount) * 0.024).toFixed(2)}</span>
      </div>
    </div>

    {/* Total Amount */}
    <div style={{ 
      backgroundColor: '#f5f5f5', 
      padding: '16px', 
      borderRadius: '8px',
      fontSize: '18px', 
      fontWeight: 'bold' 
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Total</span>
        <span>{currency} {calculateTotalAmount(discountedAmount || paymentAmount)}</span>
      </div>
    </div>
  </Form>
</Modal>




    </Layout>
  );


};

export default Booking;
