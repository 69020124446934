import React, { useState } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design CSS reset

const WaitlistPopup = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 'Teacher',
    location: '',
    country: '',
    dancestyle: 'EDM',
  });

  const dancestyles = ['EDM', 'Indian Classical Dance', 'Ballet', 'Hip-Hop', 'Salsa', 'Contemporary', 'Jazz', 'K-Pop', 'Others'];

  const handleFormChange = (changedValues, allValues) => {
    setFormData(allValues); // Sync the entire form state
  };

  const handleSubmit = async () => {


    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbwo2FwveT5SLqg2RGTplR7mMiT_vnY-PH3JVhPAJrU9HHXe59CBAD8KqyMn75lidnsm/exec', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
    
      alert('Thank you for joining the waitlist!');
      onClose();
    } catch (error) {
     
      alert('There was an error submitting the form. Please try again.');
    }
  };

  return (
    <Modal
      title="Join MyDanceHall"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      centered
    >
      <p>Fill in the details below to join our waitlist!</p>
      <Form
        layout="vertical"
        initialValues={formData}
        onValuesChange={handleFormChange}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter your name!' }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please enter your email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item label="I want to:" name="role">
          <Select>
            <Select.Option value="Teacher">Teach</Select.Option>
            <Select.Option value="Student">Learn</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: 'Please enter your location!' }]}
        >
          <Input placeholder="Enter your location" />
        </Form.Item>

        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please enter your country!' }]}
        >
          <Input placeholder="Enter your country" />
        </Form.Item>

        <Form.Item label="Dance Style" name="dancestyle">
          <Select>
            {dancestyles.map((style) => (
              <Select.Option key={style} value={style}>
                {style}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WaitlistPopup;
